import React from 'react'
import { Section } from 'components/Section'

export const Skills = () => {
  return (

    <Section className="skills">

      <h2 className="section-heading">skills</h2>

      <article className="skills-list">
        <h3>Code</h3>
        <ul>
          <li>HTML5</li>
          <li>CSS3</li>
          <li>SASS</li>
          <li>Javascript ES6</li>
          <li>React</li>
          <li>Redux</li>
          <li>Styled components</li>
          <li>Next.js</li>
          <li>Node.js</li>
        </ul>
      </article>

      <article className="skills-list">
        <h3>Tools</h3>
        <ul>
          <li>Git</li>
          <li>Mongo DB</li>
          <li>Postman</li>
          <li>VS Code</li>
          <li>WordPress</li>
          <li>Zeplin</li>
          <li>Jira</li>
          <li>Photoshop</li>
          <li>Illustrator</li>
        </ul>
      </article>

      <article className="skills-list">
        <h3>Other</h3>
        <ul>
          <li>Web accessibility</li>
          <li>Agile methodology</li>
          <li>Pair programming</li>
          <li>Mob programming</li>
          <li>UI Design</li>
        </ul>
      </article>

    </Section>

  )
}
