import React from 'react'
import { SoMeIcon } from './SoMeIcon'

export const Icons = () => {
  return (
    <div className="icons">
      <SoMeIcon
        link="https://www.linkedin.com/in/kajsaholmstrom/"
        faIcon="fab fa-linkedin-in"
        srText="Linked in" />
      <SoMeIcon
        link="https://github.com/crushoftheyear"
        faIcon="fab fa-github"
        srText="Github" />
      <SoMeIcon
        link="mailto:kajsalovisaholmstrom@gmail.com"
        faIcon="fas fa-paper-plane"
        srText="E-mail" />
    </div>
  )
}
