import React from 'react'

import { Header } from 'components/Header'
import { Hero } from 'components/Hero'
import { Projects } from 'components/Projects'
import { Skills } from 'components/Skills'
import { Footer } from 'components/Footer'

import 'scss/app.scss'

export const App = () => {

  return (

    <div>
      <Header />
      <Hero />
      <Projects />
      <Skills />
      <Footer />
    </div>

  )
}
