import React from 'react'
import { Section } from './Section'
import { ProjectItem } from './ProjectItem'

import happythoughts from '../img/happythoughts.png'
import todo from '../img/todo.png'
import wishboard from '../img/wishboard.png'
import resursgruppen from '../img/resursgruppen.png'
import frootloopsum from '../img/frootloopsum.png'
import randomizer from '../img/randomizer.png'
import weather from '../img/weather.png'
import andy from '../img/andy.png'

export const Projects = () => {
  return (

    <Section className="projects">

      <h2 className="hidden">Projects</h2>

      <ProjectItem
        title="Wishboard"
        desc="A web app where you create wishboards for events to share with friends."
        img={wishboard}
        alt="Wishboard screenshot"
        link="https://wishboard.netlify.app/"
        git="https://github.com/crushoftheyear/wishboard"
        labels={['Javascript', 'React', 'Redux', 'Node.js', 'MongoDB', 'Express']} />

      <ProjectItem
        title="Happy Thoughts"
        desc="A Twitter-like web app using an API to render & post happy thoughts."
        img={happythoughts}
        alt="Happy Thoughts screenshot"
        link="https://happythoughts.netlify.app/"
        git="https://github.com/crushoftheyear/project-happy-thoughts"
        labels={['Javascript', 'React', 'Node.js', 'MongoDB', 'Express']} />

      <ProjectItem
        title="Todo app"
        desc="Todo app for today's tasks."
        img={todo}
        alt="Todo app screenshot"
        link="https://todays.netlify.app/"
        git="https://github.com/crushoftheyear/project-todos"
        labels={['Javascript', 'React', 'Redux']} />

      <ProjectItem
        title="Weather app"
        desc="WIP weather app."
        img={weather}
        alt="Weather app screenshot"
        link="https://technigo-weather-app.netlify.app/"
        labels={['Javascript', 'API']} />

      <ProjectItem
        title="Randomizer"
        desc="Just a simple randomizer."
        img={randomizer}
        alt="Randomizer screenshot"
        link="https://kajsalovisa.com/randomizer/"
        labels={['Javascript', 'SASS']} />

      <ProjectItem
        title="Frootloopsum"
        desc="Placeholder text generator."
        img={frootloopsum}
        alt="Frootloopsum image"
        link="https://kajsalovisa.com/frootloopsum/"
        labels={['HTML', 'SASS', 'Javascript']} />

      <ProjectItem
        title="Resursgruppen"
        desc="Site for accounting office Resursgruppen."
        img={resursgruppen}
        alt="Project image"
        link="https://www.resursgruppen.se/"
        labels={['Javascript', 'Wordpress']} />

      <ProjectItem
        title="Portfolio site"
        desc="Portfolio site for a friend."
        img={andy}
        alt="Portfolio screenshot"
        link="http://www.andydahlstrom.com/"
        labels={['Wordpress']} />

    </Section>

  )
}
