import React from 'react'
import { Icons } from './Icons'

export const Header = () => {
  return (
    <header>
      <div className="container">

        <div className="logo">
          Kajsa Holmström
        </div>

        <Icons />

      </div>
    </header >
  )
}
