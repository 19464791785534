import React from 'react'
import { Icons } from './Icons'

export const Footer = () => {
  return (
    <footer>
      <div className="container">

        <Icons />

      </div>
    </footer>
  )
}
