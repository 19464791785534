import React from 'react'

export const Section = ({ children, className }) => {
  return (

    <section className={className}>
      <div className="container">
        {children}
      </div>
    </section>

  )
}
