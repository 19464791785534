import React from 'react'

export const SoMeIcon = ({ link, faIcon, srText }) => {
  return (
    <a href={link}>
      <i className={faIcon} />
      <span className="sr-only">{srText}</span>
    </a>
  )
}
