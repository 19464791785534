import React, { useRef, useEffect } from 'react'
import { TweenMax, Power3 } from 'gsap'
import { Section } from 'components/Section'

export const Hero = () => {

  let animItem = useRef(null)

  useEffect(() => {
    TweenMax.to(
      animItem,
      2,
      {
        opacity: 1,
        y: 20,
        ease: Power3.easeOut,
        delay: 1
      }
    )
  }, [])

  return (

    <Section className="hero">
      <h1 ref={el => { animItem = el }}>~ front end developer & designer</h1>
    </Section>

  )
}
