import React from 'react'

export const ProjectItem = ({ title, desc, img, alt, link, git, labels }) => {
  return (

    <article className="project-item">

      <div className="item-img">
        <div className="ratio" />
        <img src={img} alt={alt} />
        <a className="overlay" href={link} target="_blank" rel="noopener noreferrer">
          <span className="sr-only">Link to project site</span>
        </a>
      </div>

      <div className="item-info">
        <h3>{title}</h3>
        <p>{desc}</p>

        <ul className="labels">
          {labels.map((item) => (
            <li>{item}</li>
          ))}
        </ul>

        <div className="project-links">
          <a href={link} target="_blank" rel="noopener noreferrer">Go to site</a>
          {git && <a href={git} target="_blank" rel="noopener noreferrer">GitHub</a>}
        </div>

      </div>

    </article>

  )
}
